import { createContext, ReactNode, useContext, useState } from "react";
import { Strong, Text } from "../components/catalyst/text";
import NotificationItem from "../components/Notification";

export const NotificationContext = createContext({
  notify: (notif: Notification) => {},
});

type Notification = {
  title?: string;
  subtitle?: string;
  icon?: ReactNode;
};

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const notify = (notif: Notification) => {
    setNotifications((prev) => prev.concat([notif]));
  };

  const handleClose = (idx: number) => {
    setNotifications((prev) => {
      const cpy = [...prev];
      cpy.splice(idx, 1);

      return cpy;
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        notify,
      }}
    >
      {children}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {notifications.map((a, idx) => (
            <NotificationItem
              key={idx}
              onClose={() => handleClose(idx)}
              icon={a.icon}
            >
              {a.title && <Strong>{a.title}</Strong>}
              {a.subtitle && <Text>{a.subtitle}</Text>}
            </NotificationItem>
          ))}
        </div>
      </div>
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const { notify } = useContext(NotificationContext);
  return notify;
};
