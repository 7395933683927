/**
 * TODO: Update this component to use your client-side framework's link
 * component. We've provided examples of how to do this for Next.js, Remix, and
 * Inertia.js in the Catalyst documentation:
 *
 * https://catalyst.tailwindui.com/docs#client-side-router-integration
 */

import * as Headless from "@headlessui/react";
import React, { forwardRef } from "react";
import { Link as DomLink } from "react-router";

export const Link = forwardRef(function Link(
  props: { to: string } & React.ComponentPropsWithoutRef<"a">,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  return (
    <Headless.DataInteractive>
      {
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <DomLink
          className="font-semibold text-blue-600 hover:text-blue-500"
          {...props}
          ref={ref}
        />
      }
    </Headless.DataInteractive>
  );
});
