import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import dayjs from "dayjs";
import { useCallback } from "react";
import { AuthenticationResponse } from "../types/api/user";
import useAuth from "./use-auth";
import useUnauthInterceptor from "./use-unauth-interceptor";

export default function useAxios() {
  const { auth, authed, setAuthed, setUnauthed } = useAuth();
  const unauthInterceptor = useUnauthInterceptor();

  const client = useCallback(
    (signal?: AbortSignal) => {
      const instance = axios.create({
        baseURL: window.config.API_URL,
        headers: {
          Authorization: authed ? `Bearer ${auth.token}` : "",
        },
        signal,
      });

      if (authed) {
        instance.interceptors.response.use((value) => value, unauthInterceptor);

        const refreshToken = (config: InternalAxiosRequestConfig) => {
          return instance
            .post(`/v1/auth/refresh`)
            .then(({ data }: { data: AuthenticationResponse }) => {
              setAuthed(data.access_token, data.expires_in, data.user);
              config.headers.Authorization = `Bearer ${data.access_token}`;
            })
            .catch((error: AxiosError) => {
              if (error.code === "ERR_CANCELED") {
                return;
              }

              setUnauthed();
              console.error(error);
              throw new Error("failed to refresh jwt", {
                cause: error,
              });
            });
        };

        instance.interceptors.request.use(
          async (request: InternalAxiosRequestConfig) => {
            const { url } = request;

            if (
              url !== undefined &&
              !url.includes("refresh") &&
              dayjs(auth.expires).isBefore(dayjs())
            ) {
              await refreshToken(request);
            }

            return request;
          }
        );
      }

      return instance;
    },
    [
      auth.token,
      auth.expires,
      authed,
      setAuthed,
      setUnauthed,
      unauthInterceptor,
    ]
  );

  return client;
}
