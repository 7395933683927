import { Button } from "../../components/catalyst/button";

export default function Error() {
  return (
    <div className="text-center">
      <p className="text-base font-semibold text-blue-600">Oh no!</p>
      <h1 className="mt-4 text-balance text-5xl font-semibold tracking-tight text-gray-900 dark:text-white sm:text-7xl">
        Something went wrong.
      </h1>
      <p className="mt-6 text-pretty text-lg font-medium text-zinc-500 sm:text-xl/8">
        Sorry, we couldn't load what you're looking for, please try again in a
        little bit.
      </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <Button onClick={() => window.location.reload()}>Retry</Button>
      </div>
    </div>
  );
}
