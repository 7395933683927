import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { UserIcon } from "@heroicons/react/24/outline";
import { useLocation } from "react-router";
import useAuth from "../hooks/use-auth";
import useLogout from "../hooks/use-logout";
import { Avatar } from "./catalyst/avatar";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "./catalyst/dropdown";
import { Link } from "./catalyst/link";
import {
  Navbar,
  NavbarItem,
  NavbarLabel,
  NavbarSection,
  NavbarSpacer,
} from "./catalyst/navbar";
import {
  Sidebar,
  SidebarBody,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
} from "./catalyst/sidebar";

const rootNav = [
  {
    name: "Dashboard",
    href: "/dashboard",
  },
];

export function AppSidebar() {
  const location = useLocation();

  return (
    <Sidebar>
      <SidebarHeader>
        <SidebarItem to="/">
          <Avatar src="/logo.svg" />
          <SidebarLabel>Calibratly</SidebarLabel>
        </SidebarItem>
      </SidebarHeader>
      <SidebarBody>
        <SidebarSection>
          {rootNav.map((item, key) => (
            <SidebarItem
              key={key}
              to={item.href}
              current={location.pathname === item.href}
            >
              {item.name}
            </SidebarItem>
          ))}

          <SidebarItem
            to="/upload/logs"
            current={location.pathname === "/upload/logs"}
          >
            Upload Logs
          </SidebarItem>
        </SidebarSection>
      </SidebarBody>
    </Sidebar>
  );
}

export function AppNavbar() {
  const { authed } = useAuth();
  const location = useLocation();
  const { logout, loading } = useLogout(true);

  return (
    <Navbar className="px-5">
      <NavbarSection className="max-lg:hidden">
        <NavbarItem to="/">
          <Avatar
            alt="Calibratly"
            src="/logo.svg"
            className="outline-0 size-8"
          />
          <NavbarLabel>Calibratly</NavbarLabel>
        </NavbarItem>
      </NavbarSection>
      {authed && (
        <NavbarSection className="max-lg:hidden">
          {rootNav.map((item, key) => (
            <NavbarItem
              key={key}
              to={item.href}
              current={location.pathname === item.href}
            >
              {item.name}
            </NavbarItem>
          ))}
          <Dropdown>
            <DropdownButton
              as={NavbarItem}
              current={location.pathname.startsWith("/upload")}
            >
              Upload
              <ChevronDownIcon />
            </DropdownButton>
            <DropdownMenu anchor="bottom start">
              <DropdownItem to="/upload/logs">
                <DropdownLabel>Logs</DropdownLabel>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavbarSection>
      )}
      <NavbarSpacer />
      <NavbarSection>
        {authed ? (
          <Dropdown>
            <DropdownButton as={NavbarItem}>
              <UserIcon />
            </DropdownButton>
            <DropdownMenu anchor="bottom end">
              <DropdownItem to="/user/settings">Settings</DropdownItem>
              <DropdownItem disabled={loading} onClick={logout}>
                Sign out
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        ) : (
          <Link
            to="/signin"
            className="text-sm/6 font-semibold text-gray-900 dark:text-white"
          >
            Sign in <span aria-hidden="true">&rarr;</span>
          </Link>
        )}
      </NavbarSection>
    </Navbar>
  );
}
