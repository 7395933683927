import Cookie from "js-cookie";
import { createContext, useContext, useEffect, useState } from "react";

type Theme = "light" | "dark";

export const ThemeContext = createContext<{
  theme: Theme;
  setTheme: (theme: Theme) => void;
}>({
  theme: "light",
  setTheme: () => {},
});

const THEME_COOKIE = "calibratly-theme";
export default function ThemeProvider(props: React.PropsWithChildren) {
  const [theme, setThemeValue] = useState<Theme>(() => {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      return "dark";
    }

    return "light";
  });

  const setTheme = (theme: Theme) => {
    Cookie.set(THEME_COOKIE, theme);
    setThemeValue(theme);
  };

  useEffect(() => {
    document.documentElement.classList.toggle("dark", theme === "dark");
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  const { theme } = useContext(ThemeContext);
  return theme;
}
