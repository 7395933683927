import { lazy, useContext } from "react";
import {
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouteObject,
  ScrollRestoration,
  useLocation,
  useSearchParams,
} from "react-router";
import Layout from "./app/dashboard/layout";
import NotFound from "./app/utilities/notfound";
import { UserContext, UserProvider } from "./context/user";
import useAuth from "./hooks/use-auth";

const Dashboard = lazy(() => import("./app/dashboard/Dashboard"));
const ForgotPassword = lazy(() => import("./app/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("./app/auth/ResetPassword"));
const SignIn = lazy(() => import("./app/auth/signin"));
const SignUp = lazy(() => import("./app/auth/signup"));
const EditLog = lazy(() => import("./app/dashboard/logs/Edit"));
const Logs = lazy(() => import("./app/dashboard/logs/Logs"));
const UploadLogs = lazy(() => import("./app/dashboard/upload/logs"));
const UserSettings = lazy(() => import("./app/dashboard/user/settings"));
const VerifyEmail = lazy(() => import("./app/dashboard/user/verify-email"));
const Marketing = lazy(() => import("./app/marketing/Marketing"));
const PrivacyPolicy = lazy(() => import("./app/marketing/PrivacyPolicy"));
const TermsOfService = lazy(() => import("./app/marketing/TermsOfService"));

function Authed() {
  const location = useLocation();
  const { authed } = useAuth();

  if (!authed) {
    let redirect_url = "/signin";
    if (!["/", "/dashboard"].includes(location.pathname)) {
      redirect_url += `?from=${encodeURIComponent(`${location.pathname}${location.hash}${location.search}`)}`;
    }

    return <Navigate to={redirect_url} />;
  }

  return (
    <UserProvider>
      <Layout>
        <ScrollRestoration />
        <Outlet />
      </Layout>
    </UserProvider>
  );
}

function Verified() {
  const { user } = useContext(UserContext);

  if (!user.email_verified_at) {
    return <Navigate to="/user/verify-email" />;
  }

  return (
    <>
      <ScrollRestoration />
      <Outlet />
    </>
  );
}

function Guest() {
  const [params] = useSearchParams();
  const { authed } = useAuth();

  if (authed) {
    let redirect_url = "/dashboard";
    if (params.has("from")) {
      redirect_url = decodeURIComponent(params.get("from") || "");
    }

    return <Navigate to={redirect_url} replace />;
  }

  return (
    <>
      <ScrollRestoration />
      <Outlet />
    </>
  );
}

export const routes: RouteObject[] = createRoutesFromElements([
  <Route element={<Guest />}>
    <Route path="/signin" Component={SignIn} />
    <Route path="/signup" Component={SignUp} />
    <Route path="/reset-password" Component={ForgotPassword} />
    <Route path="/reset-password/:token" Component={ResetPassword} />
  </Route>,
  <Route element={<Authed />}>
    <Route path="/user/settings" Component={UserSettings} />
    <Route path="/user/verify-email/:user_id?/:hash?" Component={VerifyEmail} />
    <Route element={<Verified />}>
      <Route path="/upload/logs" Component={UploadLogs} />
      <Route path="/dashboard" Component={Dashboard} />
      <Route path="/logs/:logId/edit" Component={EditLog} />,
    </Route>
  </Route>,
  <Route path="/logs/:logId" Component={Logs} />,
  <Route path="/" Component={Marketing} />,
  <Route path="/terms-of-service" Component={TermsOfService} />,
  <Route path="/privacy-policy" Component={PrivacyPolicy} />,
  <Route path="*" Component={NotFound} />,
]);
