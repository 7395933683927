import React from "react";
import { StackedLayout } from "../../components/catalyst/stacked-layout";
import { AppNavbar, AppSidebar } from "../../components/Navbar";

export default function Layout({
  header,
  children,
}: { header?: React.ReactNode } & React.PropsWithChildren) {
  return (
    <div className="min-h-full bg-gray-50 dark:bg-gray-900">
      <StackedLayout navbar={<AppNavbar />} sidebar={<AppSidebar />}>
        {children}
      </StackedLayout>
    </div>
  );
}
