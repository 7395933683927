import { Button } from "../../components/catalyst/button";
import useAuth from "../../hooks/use-auth";

export default function NotFound() {
  const { authed } = useAuth();

  return (
    <div className="text-center">
      <p className="text-base font-semibold text-blue-600">404</p>
      <h1 className="mt-4 text-balance text-5xl font-semibold tracking-tight text-gray-900 dark:text-white sm:text-7xl">
        Page not found
      </h1>
      <p className="mt-6 text-pretty text-lg font-medium text-zinc-500 sm:text-xl/8">
        Sorry, we couldn’t find the page you’re looking for.
      </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <Button to={authed ? "/dashboard" : "/"}>Go back home</Button>
      </div>
    </div>
  );
}
