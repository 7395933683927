import dayjs from "dayjs";
import { useCallback, useMemo } from "react";

import { User } from "../types/api/user";

export const AUTH_STORAGE_KEY = "calibratly-auth";

export interface AuthState {
  token: string;
  expires: dayjs.Dayjs;
  user: User;
}

export const DEFAULT_AUTH_STATE: AuthState = {
  token: "",
  expires: dayjs(),
  user: {
    id: "",
    username: "",
    email: "",
    created_at: new Date(),
    updated_at: new Date(),
  },
};

export default function useAuth() {
  const auth = (function () {
    try {
      const value = window.localStorage.getItem(AUTH_STORAGE_KEY);
      if (!value) {
        throw new Error("Failed to get read auth state from local storage.");
      }

      return JSON.parse(value) as AuthState;
    } catch (error) {
      return DEFAULT_AUTH_STATE;
    }
  })();

  const authed = useMemo(() => {
    return auth.token !== "";
  }, [auth.token]);

  const setAuthed = useCallback(
    (token: string, expires: number, user: User) => {
      const authState = {
        token,
        expires: dayjs().add(expires, "seconds"),
        user,
      };

      try {
        window.localStorage.setItem(
          AUTH_STORAGE_KEY,
          JSON.stringify(authState)
        );
      } catch (error) {
        throw new Error("Failed to set auth state.", { cause: error });
      }
    },
    []
  );

  const setUnauthed = useCallback(() => {
    try {
      window.localStorage.removeItem(AUTH_STORAGE_KEY);
    } catch (error) {
      throw new Error("Failed to clear auth state.", { cause: error });
    }
  }, []);

  return { auth, authed, setAuthed, setUnauthed };
}
