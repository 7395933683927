import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router";
import "./app.css";
import { NotificationProvider } from "./context/notification";
import ThemeProvider from "./context/theme";
import { routes } from "./router";

function App() {
  return (
    <ThemeProvider>
      <NotificationProvider>
        <RouterProvider router={createBrowserRouter(routes)} />
      </NotificationProvider>
    </ThemeProvider>
  );
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
