import { AxiosError } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router";
import useAuth from "./use-auth";
import useAxios from "./use-axios";

const DEFAULT_LOGOUT_ERROR =
  "There was an error signing you out, please try again later.";

export default function useLogout<T>(redirect?: boolean) {
  const axios = useAxios();
  const navigate = useNavigate();
  const { setUnauthed } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogoutClick = (el: React.MouseEvent<T>) => {
    el.preventDefault();
    setLoading(true);
    setError("");

    axios()
      .post("/v1/auth/logout")
      .then(() => {
        setUnauthed();
        if (redirect) {
          navigate("/signin");
        }
      })
      .catch((error: AxiosError) => {
        console.error(error);
        setError(DEFAULT_LOGOUT_ERROR);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { logout: handleLogoutClick, loading, error };
}
